import React from "react";

import { useStyles } from "./contentStyles";
import Typography from "@material-ui/core/Typography"

export default function ContentEnRekvisits(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography variant="h4">Busan IT Automatization</Typography>
      <br />
      <ul className={classes.unstyled}>
        <li itemProp="name">Name: Busan IT Automatization"</li>
        <li>Address: 47351, 9, Sincheon-daero 65beon-gil, Busanjin-gu, Busan,
Dain Royal Palace</li>
        <li>Email: info @ busation.com</li>
      </ul>
    </div>
  );
}

ContentEnRekvisits.propTypes = {
};

ContentEnRekvisits.defaultProps = {
};
