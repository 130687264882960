import React from "react";
import SEO from "../components/seo";
import Header from "../components/header";
import { menuItemsEn } from "../constants/menuItems";
import Footer from "../components/footer";
import Layout from "../components/layout";
import { SITE_TITLE_EN } from "../constants/siteMetadata";
import ContentEnInfo from "../components/contentEnInfo";
import ClientOnly from "../components/client-only";

export default function R() {
  return (
    <Layout>
      <SEO title="Info" description="Contact Information" />
      <ClientOnly>
        <Header title={SITE_TITLE_EN} drawerMenuItems={menuItemsEn} />
      </ClientOnly>
      <ClientOnly>
        <ContentEnInfo />
      </ClientOnly>
      <ClientOnly>
        <Footer />
      </ClientOnly>
    </Layout>
  );
}
